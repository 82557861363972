import { ILabelValue } from "@roosevelt/common-ui-lib/select";
import { IPTAppGeneralState, IPTUserInfo, IUiAuthorizations } from "./state";
import { IUserAuthObj, IViewManageUserAuthsState } from "../viewManageUserAuths/state";
import { IUserListObj } from "../user-admin/state";
import { IAlertsState } from "../alerts/state";
import { ISupportingDocumentsState } from "../documents-store/state";

export const getAdminPayersInfo = (state: {appGeneralState: IPTAppGeneralState}): IPTUserInfo => state.appGeneralState.adminPayers.data;
export const getPayers = (state: {appGeneralState: IPTAppGeneralState}): ILabelValue[] => state.appGeneralState.payers.data;
export const getStates = (state: {appGeneralState: IPTAppGeneralState}): ILabelValue[] => state.appGeneralState.states.data;
export const getUiAuthInfo = (state: {appGeneralState: IPTAppGeneralState}): IUiAuthorizations => state.appGeneralState.auths.data;
export const getTotalUserAuths = (state: {appGeneralState: IPTAppGeneralState}): IUserAuthObj[] => state.appGeneralState.userAuths.data.userAuthorizations;
export const getUserRoleInfo = (state: {appGeneralState: IPTAppGeneralState}): IUserListObj => state.appGeneralState.userRole.data.users[0];


export const isFetchingAnyApi = (state: { appGeneralState: IPTAppGeneralState, ptAlertsState: IAlertsState, supportingDocumentsState: ISupportingDocumentsState, viewManageUserAuthsState: IViewManageUserAuthsState }): boolean =>
    state.ptAlertsState.isFetchingAlerts ||
    state.supportingDocumentsState.supportingDocuments.isFetching ||
    state.supportingDocumentsState.associatedSubgroups.isFetching ||
    state.supportingDocumentsState.contractDocumentsState.isFetching ||
    state.viewManageUserAuthsState.userAuths.isFetchingAuths ||
    state.viewManageUserAuthsState.manageAuths.isUpdatingAuths ||
    state.appGeneralState.userRole.isFetching ||
    state.appGeneralState.userAuths.isFetchingAuths ||
    state.appGeneralState.auths.fetching ||
    state.appGeneralState.adminPayers.fetching ||
    state.appGeneralState.payers.fetching ||
    state.appGeneralState.states.fetching;