import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getUserIdToViewManageAuths } from '../store/user-admin/selector';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { ICreateManageUserState, IUserListObj } from '../store/user-admin/state';
import { initialViewManageUserState, IUserAuthObj, IViewManageUserAuthsState } from '../store/viewManageUserAuths/state';
import { ViewManageUserAuthsAsync } from '../store/viewManageUserAuths/async';
import { Router } from '@angular/router';
import { getUpdateUserAuthsErrors, getUserAuthsErrors } from '../store/viewManageUserAuths/selector';
import { IPTAppGeneralState } from '../store/appGeneral/state';
import { getUserRoleInfo } from '../store/appGeneral/selector';

@Component({
  selector: 'pt-app-view-manage-user-auths',
  templateUrl: './view-manage-user-auths.component.html',
  styleUrl: './view-manage-user-auths.component.scss'
})
export class ViewManageUserAuthsComponent implements OnInit, OnDestroy{
  luxId = this.constructor.name+ 'PT-ViewManageUserAuthsComponent';
  getUserIdToViewManageAuths$: Observable<{userId: string; userType: string;}> = this.lux.get(getUserIdToViewManageAuths, this.luxId);
  getErrors$: Observable<string> = this.lux.get(getUserAuthsErrors, this.luxId);
  getUpdateAuthErrors$: Observable<string> = this.lux.get(getUpdateUserAuthsErrors, this.luxId);
  getUserRoleInfo$: Observable<IUserListObj> = this.lux.get(getUserRoleInfo, this.luxId);

  fetchingUserAuthsErrors: string = '';
  selectedUserInfo: {userId: string; userType: string;} = {userId: '', userType: ''};
  userAndAdminAuths: IUserAuthObj[] = [];
  pageIndex = 0;
  pageSize = 100;
  totalAuths = 0;
  currentPage = 0;
  editAuthorizations: boolean = false;
  showConfirmation: boolean = false;
  changeTo: String = '';
  disableSave = true;
  formChangesOnEdit: any[] = [];
  updateUserAuthsErrors: string = '';
  IS_AGENCY_ADMIN: boolean = false;
  userInfo: IUserListObj = {
    userName: '',
    userStatus: '',
    userType: '',
    roleName: '',
    emailAddress: ''
  };
  constructor(
    private lux: Lux<{ createManageUserState: ICreateManageUserState, viewManageUserAuthsState: IViewManageUserAuthsState, appGeneralState: IPTAppGeneralState}>,
    private viewManageUserAuthService :ViewManageUserAuthsAsync,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getErrors$.subscribe(errs => this.fetchingUserAuthsErrors = errs);
    this.getUpdateAuthErrors$.subscribe(errs => this.updateUserAuthsErrors = errs);
    this.getUserIdToViewManageAuths$.subscribe(userInfo => {
      this.selectedUserInfo = userInfo;
      this.getUserAuths({'previousPageIndex':0,'pageIndex':0,'length':0})
    });
    this.getUserRoleInfo$.subscribe(userInfo => {
      this.userInfo = userInfo;
    })
  }

  getUserAuths(page: any) {
    this.userAndAdminAuths = [];
    if (page.pageSize) { this.pageSize = page.pageSize; }
    this.pageIndex = page && page.pageIndex ? Number(page.pageIndex) : 0;
    if (isNaN(this.pageIndex)) {
      return;
    }
    const REQUEST = {
      pagination: {offset: this.pageIndex, limit: this.pageSize},
      sort: [{order: "DESC",fieldName: "TIN"}],
      userIdentifier: this.selectedUserInfo.userId
    };
    this.viewManageUserAuthService.getUserAuths(REQUEST).subscribe(auths => {  
      this.totalAuths = auths.pagination.totalRecords;
      this.pageIndex = auths.pagination.offset;
      this.userAndAdminAuths = auths.userAuthorizations;
    });
  }

  convertUserDelegate(changeTo: String) {
    this.changeTo = changeTo;
    this.showConfirmation = true;
  }

  saveAuths() {
    const UNIQUE_AUTHS = this.formChangesOnEdit.length ? this.findTheUniqueChange() : [];
    const REQUEST = {
      targetUserIdentifier: this.selectedUserInfo.userId,
      ...(!this.IS_AGENCY_ADMIN && (this.changeTo === '' || (this.changeTo === 'user' && this.formChangesOnEdit.length))) && {
        authorizations: UNIQUE_AUTHS.map(auth => {
          const obj = {
            adminPayer: auth.adminPayer,
            ...auth.agencyId && { agencyId: auth.agencyId },
            ...!auth.agencyId && { agentAgencyId: auth.agentAgencyId },
            accessLevel: auth.agencyId ? 'custom' : 'all',
            assignedAuthorizations: [
              {
                ...auth.agencyId && { agentAgencyId: auth.agentAgencyId },
                ...(auth.groupCommissionStatementsInd !== undefined) && { groupCommissionStatementsInd: auth.groupCommissionStatementsInd },
                ...(auth.individualCommissionStatementsInd !== undefined) && { individualCommissionStatementsInd: auth.individualCommissionStatementsInd},
                ...(auth.groupBookOfBusinessInd !== undefined) && { groupBookOfBusinessInd: auth.groupBookOfBusinessInd },
                ...(auth.individualBookOfBusinessInd !== undefined) && { individualBookOfBusinessInd: auth.individualBookOfBusinessInd},
                ...(auth.documentsInd !== undefined) && { documentsInd: auth.documentsInd }
              }
            ]
          }
          return obj;
        })
      },
      ...(this.IS_AGENCY_ADMIN && (this.changeTo === '' || (this.changeTo === 'user' && this.formChangesOnEdit.length))) && {
        authorizations: Object.keys(UNIQUE_AUTHS).map(agencyId => {
          let assignedAuthorizations = UNIQUE_AUTHS[agencyId].map(changdAuth => {
            const obj = {
              agentAgencyId: changdAuth.agentAgencyId,
              ...(changdAuth.groupCommissionStatementsInd !== undefined) && { groupCommissionStatementsInd: changdAuth.groupCommissionStatementsInd },
              ...(changdAuth.individualCommissionStatementsInd !== undefined) && { individualCommissionStatementsInd: changdAuth.individualCommissionStatementsInd},
              ...(changdAuth.groupBookOfBusinessInd !== undefined) && { groupBookOfBusinessInd: changdAuth.groupBookOfBusinessInd },
              ...(changdAuth.individualBookOfBusinessInd !== undefined) && { individualBookOfBusinessInd: changdAuth.individualBookOfBusinessInd},
              ...(changdAuth.documentsInd !== undefined) && { documentsInd: changdAuth.documentsInd }
            }
            return obj;
          });
          return {
            adminPayer: UNIQUE_AUTHS[agencyId][0].adminPayer,
            agencyId,
            accessLevel: 'custom',
            assignedAuthorizations
          };
        })
      }
    };
    this.hitTheApi('user', REQUEST)
  }
  
  hitTheApi(role, request) {
    this.viewManageUserAuthService.manageUserAuths(role, request).subscribe(val => {
      this.lux.set(state => state.createManageUserState.userInfoToViewManageAuth, { userType: role });
      this.getUserAuths({'pageIndex':this.pageIndex,'length': this.pageSize});
      this.editAuthorizations = false;
      this.disableSave = true;
      this.formChangesOnEdit = [];
    });
  }

  proceedToNext() {
    if(this.changeTo === 'user') {
      this.editAuthorizations = true;
    } else {
      this.hitTheApi('delegate', {targetUserIdentifier: this.selectedUserInfo.userId})
    }
    this.showConfirmation = false;
  }

  detectEditChanges(formChanges: {form: any[];changed: boolean}) {
    this.disableSave = !formChanges.changed;
    this.formChangesOnEdit = formChanges.changed ? formChanges.form : [];
  }

  findTheUniqueChange() {
    let uniqueChangesInTheAuths;
    this.IS_AGENCY_ADMIN = (this.userAndAdminAuths.some(auth => !!auth.agencyId));
    const AGENCY_IDS = this.IS_AGENCY_ADMIN ? [...new Set(this.userAndAdminAuths.map(auth => auth.agencyId).filter(element => element !== undefined))] : [];
    if(AGENCY_IDS.length) {
      AGENCY_IDS.forEach(id => {
        uniqueChangesInTheAuths = {};
        uniqueChangesInTheAuths[id] = []
      })
    } else {
      uniqueChangesInTheAuths = [];
    }
    this.formChangesOnEdit.map(val => {
      const COMPARING_OBJ = JSON.parse(JSON.stringify(this.userAndAdminAuths.filter(auth => auth.agentAgencyId === val.agentAgencyId)[0]));
      let changeDetected = Object.keys(COMPARING_OBJ.targetUserAuthorizations).some(key => {
        if(val[key] !== undefined && COMPARING_OBJ.targetUserAuthorizations[key] !== val[key]) {
          return true;
        }
        return false;
      });
      if(changeDetected) {
        this.IS_AGENCY_ADMIN ? uniqueChangesInTheAuths[val.agencyId].push(val) : uniqueChangesInTheAuths.push(val);
      }
    });
    return uniqueChangesInTheAuths;
  }

  ngOnDestroy(): void {
    this.lux.set(state => state, {viewManageUserAuthsState: initialViewManageUserState});
    this.lux.destroy(this.luxId);
  }

}
