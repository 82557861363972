import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUserAuthObj } from '../../store/viewManageUserAuths/state';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'pt-app-manage-user-auths',
  templateUrl: './manage-user-auths.component.html',
  styleUrl: '../view-manage-user-auths.component.scss'
})
export class ManageUserAuthsComponent implements OnInit {
  @Input() userAndAdminAuths: IUserAuthObj[] = [];
  @Output() changesFromEdit: EventEmitter<any> = new EventEmitter();
  authsForm: FormGroup = this.fb.group({});
  snapShotOfAllCustomForms: string = '';
  formChangesHappened: boolean = false;
  tableHeaders: string[] = [];
  constructor(private fb: UntypedFormBuilder){}

  ngOnInit(): void {
    this.userAndAdminAuths = this.userAndAdminAuths.map((obj, ind) => {
      return {...obj, editIndex: ind+1}
    });
    this.populateIndsArray(this.userAndAdminAuths);
    this.generateFormGroup();
  }

  private populateIndsArray(auths: IUserAuthObj[]): void {
    let indicatorsArray: string[] = [];
    let indArrayForEmptySpaces = [
      'groupCommissionStatementsInd',
      'individualCommissionStatementsInd',
      'groupBookOfBusinessInd',
      'individualBookOfBusinessInd',
      'documentsInd',
    ];
    let items = auths;
    for (let i = 0; i < items.length; i++) {
      Object.entries(items[i].invokingUserAuthorizations).forEach(([key, value]) => {
          if (value && !indicatorsArray.includes(key)) {
            indicatorsArray.push(key);
            let indexToRemove = indArrayForEmptySpaces.indexOf(key);
            if (indexToRemove > -1) {
              indArrayForEmptySpaces.splice(indexToRemove, 1);
            }
          }
        }
      );
    }
    indicatorsArray = indicatorsArray.sort((x, y) => (x < y ? 1 : -1));
    indArrayForEmptySpaces = indArrayForEmptySpaces.sort((x, y) => x < y ? 1 : -1);
    if (indArrayForEmptySpaces && indArrayForEmptySpaces.length > 0) {
      this.tableHeaders = indicatorsArray.concat(indArrayForEmptySpaces);
    } else {
      this.tableHeaders = [...indicatorsArray];
    }
  }

  generateFormGroup() {
    this.authsForm = this.fb.group({
      groupCommissionStatementsInd: '',
      individualCommissionStatementsInd: '',
      groupBookOfBusinessInd: '',
      individualBookOfBusinessInd: '',
      documentsInd: '',
      userAuthForms: this.userAndAdminAuths.length ? new UntypedFormArray(this.userAndAdminAuths.map(agentAgency => {
        const group = this.fb.group({
          agentAgencyId: agentAgency.agentAgencyId,
          agencyId: agentAgency.agencyId,
          adminPayer: agentAgency.adminPayer,
          ...agentAgency.invokingUserAuthorizations.groupCommissionStatementsInd && { groupCommissionStatementsInd: agentAgency.targetUserAuthorizations?.groupCommissionStatementsInd || false },
          ...agentAgency.invokingUserAuthorizations.individualCommissionStatementsInd && { individualCommissionStatementsInd: agentAgency.targetUserAuthorizations?.individualCommissionStatementsInd || false },
          ...agentAgency.invokingUserAuthorizations.groupBookOfBusinessInd && { groupBookOfBusinessInd: agentAgency.targetUserAuthorizations?.groupBookOfBusinessInd || false },
          ...agentAgency.invokingUserAuthorizations.individualBookOfBusinessInd && { individualBookOfBusinessInd: agentAgency.targetUserAuthorizations?.individualBookOfBusinessInd || false },
          ...agentAgency.invokingUserAuthorizations.documentsInd && { documentsInd: agentAgency.targetUserAuthorizations?.documentsInd || false }
        });
        return group;
      })) : ''
    });

    const AUTHS_FORM_CONTRLS = this.authsForm.controls;
    const USER_AUTHS_FORM = AUTHS_FORM_CONTRLS['userAuthForms'] as UntypedFormArray;
    const AGENT_AGENCY_FORMS = USER_AUTHS_FORM.controls as FormGroup[];
    this.snapShotOfAllCustomForms = JSON.stringify(USER_AUTHS_FORM.getRawValue());

    AUTHS_FORM_CONTRLS['groupCommissionStatementsInd'].valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      AGENT_AGENCY_FORMS.map((form: UntypedFormGroup) => {
        form.controls['groupCommissionStatementsInd'] && form.controls['groupCommissionStatementsInd'].setValue(val);
      });
    });
    AUTHS_FORM_CONTRLS['individualCommissionStatementsInd'].valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      AGENT_AGENCY_FORMS.map((form: UntypedFormGroup) => {
        form.controls['individualCommissionStatementsInd'] && form.controls['individualCommissionStatementsInd'].setValue(val);
      });
    });
    AUTHS_FORM_CONTRLS['groupBookOfBusinessInd'].valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      AGENT_AGENCY_FORMS.map((form: UntypedFormGroup) => {
        form.controls['groupBookOfBusinessInd'] && form.controls['groupBookOfBusinessInd'].setValue(val);
      });
    });
    AUTHS_FORM_CONTRLS['individualBookOfBusinessInd'].valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      AGENT_AGENCY_FORMS.map((form: UntypedFormGroup) => {
        form.controls['individualBookOfBusinessInd'] && form.controls['individualBookOfBusinessInd'].setValue(val);
      });
    });
    AUTHS_FORM_CONTRLS['documentsInd'].valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      AGENT_AGENCY_FORMS.map((form: UntypedFormGroup) => {
        form.controls['documentsInd'] && form.controls['documentsInd'].setValue(val);
      });
    });

    USER_AUTHS_FORM.valueChanges.pipe(distinctUntilChanged(), startWith(USER_AUTHS_FORM.getRawValue()), debounceTime(300)).subscribe(val => {
      this.formChangesHappened = (this.snapShotOfAllCustomForms !== JSON.stringify(USER_AUTHS_FORM.getRawValue()));
      this.changesFromEdit.emit({
        form: USER_AUTHS_FORM.getRawValue(),
        changed: this.formChangesHappened
      })
    })

  }

  checkIndStatus(indicator: string): boolean {
    return (this.userAndAdminAuths.length
      && this.userAndAdminAuths.filter(u => u.invokingUserAuthorizations[indicator] === true).length > 0)
      ? true : false;
  }

  createColumnHeader(indicator: string): string {
    switch (indicator) {
      case 'groupCommissionStatementsInd':
        return 'Group Commission Statements';
      case 'individualCommissionStatementsInd':
        return 'Individual Commission Statements';
      case 'groupBookOfBusinessInd':
        return 'Group Book of Business';
      case 'individualBookOfBusinessInd':
        return 'Individual Book of Business';
      case 'documentsInd':
        return 'Documents';
      default:
        return 'indicator';
    }
  }
}
