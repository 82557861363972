import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewManageUserAuthsComponent } from './view-manage-user-auths.component';
import { TabsModule } from '@roosevelt/common-ui-lib/tabs';
import { CheckboxModule } from '@roosevelt/common-ui-lib/checkbox';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IViewManageUserAuthsState, initialViewManageUserState } from '../store/viewManageUserAuths/state';
import { ViewManageUserAuthsService } from '../store/viewManageUserAuths/api.service';
import { ViewManageUserAuthsAsync } from '../store/viewManageUserAuths/async';
import { RouterModule } from '@angular/router';
import { ViewUserAuthsComponent } from './view-user-auths/view-user-auths.component';
import { ViewManageUserAuthsRoutingModule } from './view-manage-user-auths-routing.module';
import { TableModule } from '@roosevelt/common-ui-lib/table';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { NotificationModule } from '@roosevelt/common-ui-lib/notification';
import { ManageUserAuthsComponent } from './manage-user-auths/manage-user-auths.component';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ViewManageUserAuthsComponent,
    ViewUserAuthsComponent,
    ManageUserAuthsComponent
  ],
  imports: [
    CommonModule,
    TabsModule,
    RouterModule,
    CheckboxModule,
    ViewManageUserAuthsRoutingModule,
    TableModule,
    PaginatorModule,
    NotificationModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule
  ],
  providers: [
    ViewManageUserAuthsAsync,
    ViewManageUserAuthsService
  ]
})
export class ViewManageUserAuthsModule { 
}