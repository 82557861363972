import { IPagination } from "@roosevelt/producer-lib";

export const initailUserAuthRes: IUserAuthRes = {
  pagination: {
    offset: 0,
    limit: 100
  },
  userAuthorizations: []
}

export const initialViewManageUserState: IViewManageUserAuthsState = {
  userAuths: {
    data: initailUserAuthRes,
    isFetchingAuths: false,
    errors: ''
  },
  manageAuths: {
    isUpdatingAuths: false,
    errors: ''
  }
}

export interface IViewManageUserAuthsState {
  userAuths: IViewUserAuths;
  manageAuths: IManageUserAuths;
}

export interface IManageUserAuths {
  isUpdatingAuths: boolean;
  errors: string;
}

export interface IViewUserAuths {
  data: IUserAuthRes;
  isFetchingAuths: boolean;
  errors: string;
}

export interface IUserAuthRes {
  pagination: IPagination;
  userAuthorizations: IUserAuthObj[]
}

export interface IUserAuthObj {
  adminPayer: string;
  agentAgencyId: number;
  agencyId: number;
  agentFirstName: string;
  agentLastName: string;
  agencyName: string;
  invokingUserAuthorizations: IUserAuthIndicatorObj;
  targetUserAuthorizations?: IUserAuthIndicatorObj;
  tin: string;
  npn: string;
}

export interface IUserAuthIndicatorObj {
  documentsInd: boolean;
  groupBookOfBusinessInd: boolean;
  groupCommissionStatementsInd: boolean;
  individualBookOfBusinessInd: boolean;
  individualCommissionStatementsInd: boolean;
}
