import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IUserAuthObj, IUserAuthRes, IViewManageUserAuthsState } from '../../store/viewManageUserAuths/state';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IPTAppGeneralState } from '../../store/appGeneral/state';
import { Observable } from 'rxjs';
import { getUserAuths } from '../../store/viewManageUserAuths/selector';
@Component({
  selector: 'pt-app-view-user-auths',
  templateUrl: './view-user-auths.component.html',
  styleUrl: '../view-manage-user-auths.component.scss'
})
export class ViewUserAuthsComponent implements OnInit {
  luxID = 'PT-ViewUserAuthsComponent'+this.constructor.name;
  getUserAuths$: Observable<IUserAuthRes> = this.lux.get(getUserAuths, this.luxID);
  @Input() userAndAdminAuths: IUserAuthObj[] = [];
  tableHeaders: string[] = ['tin', 'agencyName', 'npn' ,'agentName' ];
  fullIndicatorsArray: string[] = ['groupCommissionStatementsInd','individualCommissionStatementsInd','groupBookOfBusinessInd','individualBookOfBusinessInd','documentsInd'];

  constructor(
    private lux: Lux<{
      appGeneralState: IPTAppGeneralState,
      viewManageUserAuthsState: IViewManageUserAuthsState
    }>,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit() {
    this.getUserAuths$.subscribe(auths => {
      let indicatorsArray: string[] = this.populateIndsArray(auths);
      this.updateTableHeaders(indicatorsArray);
      this.userAndAdminAuths = auths.userAuthorizations;
      this.cdr.detectChanges();
    });
  }

  private populateIndsArray(auths: IUserAuthRes) {
    let indicatorsArray: string[] = [];
    let indArrayForEmptySpaces = this.fullIndicatorsArray;
    let items = auths.userAuthorizations;
    for (let i = 0; i < items.length; i++) {
      Object.entries(items[i].invokingUserAuthorizations).forEach(([key, value]) => {
        if (value && !indicatorsArray.includes(key)) {
          indicatorsArray.push(key);
          let indexToRemove = indArrayForEmptySpaces.indexOf(key);
          if (indexToRemove > -1) {
            indArrayForEmptySpaces.splice(indexToRemove, 1);
          }
        }
      });
    }
    indicatorsArray = indicatorsArray.sort((x,y) => x < y ? 1 : -1);
    if (indArrayForEmptySpaces && indArrayForEmptySpaces.length > 0) {
      for (let j = 0; j < indArrayForEmptySpaces.length; j++) {
        indicatorsArray.push('empty');
      }
    }
    return indicatorsArray;
  }

  updateTableHeaders(indicatorsArray: string[]) {
    if (indicatorsArray && indicatorsArray.length > 0) {
      this.tableHeaders = ['tin', 'agencyName', 'npn', 'agentName', ...indicatorsArray];
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxID);
  }
}
