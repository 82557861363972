<rs-tab-group>
    <rs-tab-group-title>
      <h3>User Authorizations</h3>
    </rs-tab-group-title>
    <rs-tab [label]="(editAuthorizations ? 'Manag' : 'View')+'ing User Authorizations'" style="padding: 0 30px;" >
        <div id="scrollId"></div>
        <div class="view-auth-container">
            <a routerLink="/userAdmin/manage" class="back-to-users-link"><< Back to Users List</a>
            <div class="userId">
                <div *ngIf="selectedUserInfo">
                    <span>Selected User:</span> <label>{{selectedUserInfo.userId}}</label>
                    <span style="padding-left: 15px;">User Type:</span> <label>{{selectedUserInfo.userType | titlecase }}</label>
                </div>
                <div class="btns-div">
                    @if(userInfo.userType === 'admin') {
                        <button *ngIf="!editAuthorizations && selectedUserInfo?.userType === 'user'" rs-raised-button color="primary" (click)="convertUserDelegate('delegate')">Convert To Delegate</button>
                        <button *ngIf="!editAuthorizations && selectedUserInfo?.userType === 'delegate'" rs-raised-button color="primary" (click)="convertUserDelegate('user')">Convert To User</button>
                    }
                    <button *ngIf="!editAuthorizations && selectedUserInfo?.userType !== 'delegate'" rs-raised-button color="primary" (click)="editAuthorizations = true">Edit</button>
                    <button *ngIf="editAuthorizations" rs-raised-button color="secondary" (click)="editAuthorizations = false;disableSave = true">Cancel</button>
                    <button *ngIf="editAuthorizations" rs-raised-button color="primary" (click)="saveAuths()" [disabled]="disableSave && !(changeTo === 'user')">Save</button>
                </div>
            </div>
            <div>
                <rs-notification type="error" [visible]="fetchingUserAuthsErrors.length > 0" closeable (visibleChange)="fetchingUserAuthsErrors = ''">{{fetchingUserAuthsErrors}}</rs-notification>
                <rs-notification type="error" [visible]="updateUserAuthsErrors.length > 0" closeable (visibleChange)="updateUserAuthsErrors = ''">{{updateUserAuthsErrors}}</rs-notification>
                <pt-app-view-user-auths *ngIf="!editAuthorizations" [userAndAdminAuths]="userAndAdminAuths"></pt-app-view-user-auths>
                <pt-app-manage-user-auths *ngIf="editAuthorizations && userAndAdminAuths.length > 0" [userAndAdminAuths]="userAndAdminAuths" (changesFromEdit)="detectEditChanges($event)"></pt-app-manage-user-auths>                
            </div>
            <div class="pagination-holder">
                <div style="color: red;">{{!disableSave ? 'Please SAVE your changes before navigating away from this page.' : ''}}</div>
                <ng-container *ngIf="userAndAdminAuths.length > 0">
                  <rs-paginator [pageIndex]="pageIndex" [class]="!disableSave ? 'no-events' : ''" [length]="totalAuths" [pageSize]="pageSize"
                      [pageSizeOptions]="[10,100]" (page)="getUserAuths($event)" showFirstLastButtons>
                  </rs-paginator>
                </ng-container>
            </div>
        </div>
        <rs-dialog [visible]="showConfirmation" (visibleChange)="showConfirmation = false" heading="Confirmation">
            @if (changeTo === 'delegate') {
                <p style="padding: 20px;">Are you sure about converting this user role to delegate ?</p>
            } 
            @if (changeTo === 'user')  {
                <p style="padding: 20px;">Converting delegate to user only happens in the next screen, once you save the authorizations.</p>
            }
            <div class="confirmation-btns-div">
                <button rs-raised-button color="secondary" (click)="showConfirmation = false">Cancel</button>
                <button rs-raised-button color="primary" (click)="proceedToNext()">{{changeTo === 'user' ? 'Proceed' : 'Yes'}}</button>
            </div>
        </rs-dialog>
    </rs-tab>
</rs-tab-group>