import { ICreateManageUserState, ICreateUserReq, IPageIndex, IUserDetails, IUserListReq, IUserListRes } from "./state";

export const getCreateUserReq =
    (state: { createManageUserState: ICreateManageUserState }): ICreateUserReq => state.createManageUserState.createUserReq;

export const getErrors = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.errors;

export const isUserGettingCreated = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.isUserGettingCreated;

export const isUserCreated = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.userCreated;
export const getUserslistErrors = (state: { createManageUserState: ICreateManageUserState }): string => state.createManageUserState.manageUser.errorsFetchingUsers;
export const isFetchingUserLists = (state: { createManageUserState: ICreateManageUserState }): boolean => state.createManageUserState.manageUser.isFetchingUsers;
export const getUserLists = (state: { createManageUserState: ICreateManageUserState }): IUserListRes => state.createManageUserState.manageUser.usersList;
export const getUserListReq = (state: { createManageUserState: ICreateManageUserState }): IUserListReq => state.createManageUserState.manageUser.getUsersReq;
export const getPageIndexTracker = (state: { createManageUserState: ICreateManageUserState}): IPageIndex => state.createManageUserState.manageUser.pageIndexTracker;
export const getUserIdToViewManageAuths = (state: { createManageUserState: ICreateManageUserState}): {userId: string; userType: string;} => state.createManageUserState.userInfoToViewManageAuth;
export const getUserDetails = (state: { createManageUserState: ICreateManageUserState}): IUserDetails => state.createManageUserState.manageUser.userDetails;
export const isFetchingUserDetails = (state: { createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.manageUser.isFetchingUserDetails;
export const errorFetchingUserDetails = (state: { createManageUserState: ICreateManageUserState}): string => state.createManageUserState.manageUser.errorFetchingUserDetails;
export const isUserDetailsUpdating = (state: { createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.manageUser.isUserDetailsUpdating;
export const errorUpdatingUserDetails = (state: { createManageUserState: ICreateManageUserState}): string => state.createManageUserState.manageUser.errorUpdatingUserDetails;
export const isUserDetailsUpdated = (state: { createManageUserState: ICreateManageUserState}): boolean => state.createManageUserState.manageUser.isUserDetailsUpdated;
