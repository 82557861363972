<div class="view-auth-container">
    <form [formGroup]="authsForm">
      <table>
        <thead>
          <th style="width: 144px;">TIN</th>
          <th style="width: 144px;">Agency Name</th>
          <th style="width: 144px;">NPN</th>
          <th style="width: 144px;">Agent Name</th>
          <ng-container *ngFor="let header of tableHeaders">
            <th style="width: 135px;">
              @if(checkIndStatus(header)) {
              <div style="padding: 10px 25px;">{{createColumnHeader(header)}}</div>
                <rs-checkbox id={{header}} formControlName={{header}}></rs-checkbox>
              }
            </th>
          </ng-container>
        </thead>
        <tbody>
          <tr *ngFor="let userAuth of userAndAdminAuths;let i = index;">
            <ng-container formArrayName="userAuthForms">
                <ng-container [formGroupName]="i">
                    <td>{{userAuth.tin}}</td>
                    <td>{{userAuth.agencyName}}</td>
                    <td>{{userAuth.npn}}</td>
                    <td>{{userAuth.agentFirstName + ' ' + userAuth.agentLastName}}</td>
                    <ng-container *ngFor="let header of tableHeaders">
                      <td>
                        @if(userAuth.invokingUserAuthorizations[header]) {
                          <rs-checkbox [id]="header+userAuth.agentAgencyId" formControlName={{header}}></rs-checkbox>
                        }
                      </td>
                    </ng-container>
                </ng-container>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </form>
</div>
