import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { replaceDynamicParams } from '../../utils/app.generic';

@Injectable()
export class ViewManageUserAuthsService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  getUserAuths(req:any) {
    return this.http.post<any>(this.config.urls.getUserAuths, req);
  }

  updateAuths(role: string, req) {
    const url = replaceDynamicParams(this.config.urls.manageAuths, {role});
    return this.http.post<any>(url, req);
  }
}
