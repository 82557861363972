import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { ViewManageUserAuthsService } from './api.service';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { HttpErrorResponse } from '@angular/common/http';
import { initailUserAuthRes, IViewManageUserAuthsState } from './state';

@Injectable()
export class ViewManageUserAuthsAsync {
  constructor(
    private lux: Lux<{ viewManageUserAuthsState: IViewManageUserAuthsState}>,
    private userAuthsService: ViewManageUserAuthsService,
  ) { }

  getUserAuths(req:any) {
      return of(req).pipe (
      first(),
      tap(() => {
        this.lux.set(state => state.viewManageUserAuthsState.userAuths, { data: initailUserAuthRes, isFetchingAuths: true, errors: '' })
      }),
      mergeMap(x => this.userAuthsService.getUserAuths(req)),
      tap(results => {
        this.lux.set(state => state.viewManageUserAuthsState.userAuths, { data: results, isFetchingAuths: false, errors: '' })
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map((err:any) => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.viewManageUserAuthsState.userAuths, { data: initailUserAuthRes, isFetchingAuths: false, errors: errorMessage })
        return of(errorMessage);
      })
    );
  }

  manageUserAuths(role: string, request) {
    return of(role, request).pipe (
      first(),
      tap(() => {
        this.lux.set(state => state.viewManageUserAuthsState.manageAuths, { isUpdatingAuths: true, errors: '' })
      }),
      mergeMap(x => this.userAuthsService.updateAuths(role, request)),
      tap(results => {
        this.lux.set(state => state.viewManageUserAuthsState.manageAuths, { isUpdatingAuths: false, errors: '' })
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map((err:any) => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.viewManageUserAuthsState.manageAuths, { isUpdatingAuths: false, errors: errorMessage })
        return of(errorMessage);
      })
    );
  }
}
