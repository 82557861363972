import {BasicAuthConfig} from '@roosevelt/common-ui-lib/core';
import { IPTLibUrls } from '@roosevelt/producer-toolkit-lib';

export class AppConfig implements BasicAuthConfig<IPTLibUrls> {

  appName: any;
  region: any;
  authedUrls: any | [];
  defaultTimeout: any;
  clientId: any;
  issuer: any;
  redirectUri: any;
  scopes: any | [];
  guardRedirectUri?: any;
  overrides: any = {};
  authInfoUrl: any;
  /**
   * @deprecated
   * this will be removed after okta implementation
   */
  secureAuth: any | {
    use: boolean;
    clientId: any;
    authEndpoint: any;
    authInfoUrl: any;
    authNewClientEndpoint: any;
    authLogoutEndPoint: any;
    loginRedirectRoute: any;
  };
  postLogoutRedirectEndpoint: any;
  showForgotPassword: any;
  plansAllowed: any = {};
  urls: any | {
    magnoliaUrl: string;
    userContextUrl: string;
    getPayersUrl: string;
    getCommissionsStatementsUrl: string ;
    commissionStatementDownloadUrl: string;
    ptAlertsUrl: string;
    ptGeneralAlertsUrl: string;
    getSupportingDocumentsUrl: string;
    getContractDocuments: string;
    getSubgroups: string;
    getGroupDetails: string;
    downloadDocuments: string;
    ptUIAuthorizationsUrl: string;
    createUserUrl: string;
    factorsEnrolled: string;
    factorsCatalog: string;
    sendCode: string;
    verifyCode: string;
    resendCode: string;
    getUsers: string;
    getUserAuths: string
    getProducersUrl: string;
    getProducersExportUrl: string;
    statesUrl: string;
    getUserDetails: string;
    updateUserDetails: string;
    administratorsUrl: string;
    validationUrl: string;
    bmtRedirectURL: string;
  };
  enableMfa: boolean = false;
}
