<div class="view-auth-container">
  <table rs-table [dataSource]="userAndAdminAuths" style="width:100%;" class="table search-table colored-table">
    <ng-container *ngFor="let header of tableHeaders">
      <ng-container *ngIf="header === 'tin'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1">TIN</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.tin}}</td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'agencyName'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1">Agency Name</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.agencyName}}</td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'npn'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1">NPN</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{element.npn}}</td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'agentName'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1">Agent Name</th>
          <td rs-cell *rsCellDef="let element" class="col-1">{{(element.agentFirstName || '') + ' '+
            (element.agentLastName || '')}}</td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'groupCommissionStatementsInd'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;">Group Commission Statements</div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.targetUserAuthorizations[header]" disabled></rs-checkbox>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'individualCommissionStatementsInd'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;">Individual Commission Statements</div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.targetUserAuthorizations[header]" disabled></rs-checkbox>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'groupBookOfBusinessInd'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;">Group Book of Business</div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.targetUserAuthorizations[header]" disabled></rs-checkbox>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'individualBookOfBusinessInd'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;">Individual Book of Business</div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.targetUserAuthorizations[header]" disabled></rs-checkbox>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="header === 'documentsInd'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;">Documents</div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
            <rs-checkbox [checked]="element.targetUserAuthorizations[header]" disabled></rs-checkbox>
          </td>
        </ng-container>
      </ng-container>
      <!-- this below code to add empty spaces for overall look -->
      <ng-container *ngIf="header === 'empty'">
        <ng-container rsColumnDef="{{header}}">
          <th rs-header-cell *rsHeaderCellDef class="col-1" style="text-align: center;">
            <div style="padding: 10px 25px;"></div>
          </th>
          <td rs-cell *rsCellDef="let element" class="col-1" style="text-align: center;">
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
    <tr rs-header-row *rsHeaderRowDef="tableHeaders"></tr>
    <tr rs-row *rsRowDef="let row; columns: tableHeaders"></tr>
    <tr class="rs-row" *rsNoDataRow>
      <td colspan="12" style="text-align: center; height: 150px" class="rs-cell">
        <p>No Records Found</p>
        <p>Please try again!</p>
      </td>
    </tr>
  </table>
</div>
